
import { isAliasEqual } from '@/services/helpers'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'
import { INameValuePair } from '@/types/interfaces/INameValuePair'
import { AddonFormData } from '@/types/appcontracts/AddonFormData'
import { computed, defineComponent, PropType, reactive, ref, toRaw } from 'vue'
import { useStore } from 'vuex'
import * as actions from '../../../../../store/actions.type'
import { AddonFormType } from '@/types/enums/AddonFormType'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 6 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 14 },
}

const getInitialState = (prev: AddonFormData | null = null) => {
  const initialState: AddonFormData = {
    type: AddonFormType.Single,
    single: {
      name: prev?.single?.name || null,
      value: prev?.single?.value || 0,
    },
    group: null,
  }
  return initialState
}

export default defineComponent({
  name: 'TaxSingleInputForm',
  props: {
    editData: {
      type: Object as PropType<AddonFormData | null>,
      default: () => null,
    },
  },
  setup(props) {
    const store = useStore()
    const formRef = ref()
    const isEdit = computed(() => props.editData !== null)
    const formState = reactive(getInitialState(props.editData || null))
    const isLoading = ref<boolean>(false)
    const taxOptions = computed(() => {
      return store.state.appGlobals.appConfigSettings?.taxes?.singles as INameValuePair<number>[]
    })
    const isNameUnique = async () => {
      return taxOptions.value?.some((x) => isAliasEqual(x.name, formState.single?.name || null)) &&
        !isAliasEqual(props.editData?.single?.name || null, formState.single?.name || null)
        ? Promise.reject('Name already exists.')
        : Promise.resolve(true)
    }

    const isValueValid = async () => {
      return (formState.single?.value || 0) <= 0
        ? Promise.reject('Value should be greater then 0')
        : Promise.resolve(true)
    }

    const rules = reactive({
      single: {
        name: [
          {
            required: true,
            message: 'Please enter Tax Name',
            trigger: 'blur',
            type: 'string',
          },
          { validator: isNameUnique, trigger: 'blur' },
        ],
        value: [{ validator: isValueValid, trigger: 'blur' }],
      },
    })

    const handleFinish = async () => {
      isLoading.value = true
      const docOperation: DocumentOperation<AddonFormData> = {
        action: isEdit.value ? DocumentActions.UPDATE : DocumentActions.CREATE,
        id: isEdit.value ? props.editData?.single?.name || null : null,
        payload: toRaw(formState),
        audit: null,
      }
      const isSuccess = await store.dispatch(
        `appGlobals/${actions.GlobalAction.SET_DELETE_TAX}`,
        docOperation,
      )
      isLoading.value = false
      if (isSuccess && !isEdit.value) {
        Object.assign(formState, getInitialState())
      }
    }

    return {
      formState,
      formRef,
      rules,
      labelCol,
      wrapperCol,
      isLoading,
      handleFinish,
      isEdit,
      // handleSubmit,
    }
  },
})
