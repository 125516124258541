
import { useStore } from 'vuex'
// import * as actions from '@/store/actions.type'
import { ref, defineComponent, computed } from 'vue'
import { SyncOutlined } from '@ant-design/icons-vue'
// import TaxesTable from './TaxesTable.vue'
// import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { INameValuePair } from '@/types/interfaces/INameValuePair'
import ModalSlot from '@/components/General/ModalSlot.vue'
import TaxSingleInputForm from './new/single/index.vue'
import TaxGroupInputForm from './new/group/index.vue'
import { AddonFormType } from '@/types/enums/AddonFormType'
import { AddonApplyType } from '@/types/enums/AddonApplyType'
import { IAddonGroupType } from '@/types/interfaces/IAddonGroupType'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { AddonFormData } from '@/types/appcontracts/AddonFormData'
import { DocumentActions } from '@/types/enums/DocumentActions'
import * as actions from '../../../store/actions.type'
import ButtonGroupActions from '@/components/General/ButtonGroupActions.vue'
import { ActionButtonList } from '@/types/enums/ActionButtonList'

interface IAddonTableFormat {
  name: string
  type: AddonFormType
  applyType: AddonApplyType
  value: number | INameValuePair<number>[]
  data: INameValuePair<number> | IAddonGroupType
}

const columns = [
  {
    title: 'Tax Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    slots: { customRender: 'tagType' },
  },
  {
    title: 'ApplyType',
    dataIndex: 'applyType',
    key: 'applyType',
    slots: { customRender: 'tagType' },
  },
  {
    title: 'Value',
    key: 'value',
    slots: { customRender: 'value' },
  },
  {
    title: 'Actions',
    key: 'actions',
    slots: { customRender: 'actions' },
  },
]

export default defineComponent({
  name: 'Taxes',
  components: {
    SyncOutlined,
    ModalSlot,
    TaxSingleInputForm,
    TaxGroupInputForm,
    ButtonGroupActions,
  },
  setup() {
    const store = useStore()
    const isSingleModalVisible = ref<boolean>(false)
    const isGroupModalVisible = ref<boolean>(false)
    const isLoading = ref<boolean>(false)
    const editSingleData = ref<AddonFormData | null>(null)
    const editGroupData = ref<AddonFormData | null>(null)
    const actionButtonList = [ActionButtonList.EDIT, ActionButtonList.DELETE]

    const isTaxesLoading = computed(() => store.state.appGlobals.isGlobalSetup as boolean)
    const taxList = computed(() => {
      return store.state.appGlobals.appConfigSettings?.taxes?.singles as INameValuePair<number>[]
    })
    const taxesExists = computed(() => taxList.value?.length !== 0)
    const reloadIconType = ref(false)
    const dataSource = computed(() => {
      const singlesTable =
        (store.state.appGlobals.appConfigSettings?.taxes?.singles as INameValuePair<number>[])?.map(
          (x) => {
            return {
              name: x.name,
              type: AddonFormType.Single,
              applyType: AddonApplyType.Basic,
              value: x.value,
              data: x,
            } as IAddonTableFormat
          },
        ) || []

      const groupTable =
        (store.state.appGlobals.appConfigSettings?.taxes?.groups as IAddonGroupType[])?.map((x) => {
          return {
            name: x.name,
            type: AddonFormType.Group,
            applyType: x.applyType,
            value: x.values,
            data: x,
          } as IAddonTableFormat
        }) || []

      const finalTable = [...singlesTable, ...groupTable]
      return finalTable
    })
    const handleDataReload = async () => {
      reloadIconType.value = true
      // await store.dispatch(
      //   `taxes/${actions.TaxesAction.FETCH_CONSIGNMENTS_ON_LOAD}`,
      // )
      reloadIconType.value = false
    }

    const handleDeleteClick = async (record: IAddonTableFormat) => {
      isLoading.value = true
      const payLoad = getAddFormData(record)

      // Used Payload instead of Id, as we need to determine single or group.
      const docOperation: DocumentOperation<AddonFormData> = {
        action: DocumentActions.DELETE,
        id: null,
        payload: payLoad,
        audit: null,
      }
      const isSuccess = await store.dispatch(
        `appGlobals/${actions.GlobalAction.SET_DELETE_TAX}`,
        docOperation,
      )
      console.log(isSuccess)
      isLoading.value = false
    }

    const handleEditClick = (record: IAddonTableFormat) => {
      if (record.type === AddonFormType.Single) {
        isSingleModalVisible.value = true
        editSingleData.value = getAddFormData(record)
      } else {
        isGroupModalVisible.value = true
        editGroupData.value = getAddFormData(record)
      }
    }

    const handleNewSingle = () => {
      editSingleData.value = null
      isSingleModalVisible.value = true
    }

    const handleNewGroup = () => {
      editGroupData.value = null
      isGroupModalVisible.value = true
    }

    const getAddFormData = (record: IAddonTableFormat) => {
      return {
        type: record.type,
        single:
          record.type === AddonFormType.Single ? (record.data as INameValuePair<number>) : null,
        group: record.type === AddonFormType.Group ? (record.data as IAddonGroupType) : null,
      } as AddonFormData
    }

    return {
      isTaxesLoading,
      taxesExists,
      reloadIconType,
      handleDataReload,
      taxList,
      isSingleModalVisible,
      isGroupModalVisible,
      dataSource,
      columns,
      handleDeleteClick,
      isLoading,
      handleEditClick,
      editSingleData,
      editGroupData,
      handleNewSingle,
      handleNewGroup,
      actionButtonList,
    }
  },
})
