<template>
  <div>
    <a-modal
      :title="title"
      :visible="isVisible"
      @cancel="handleCancel"
      :footer="null"
      :destroy-on-close="true"
      :width="`${width}px`"
    >
      <slot></slot>
    </a-modal>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalSlot',
  props: {
    modalTitle: {
      type: String,
      default: () => '',
    },
    modalVisible: {
      type: Boolean,
      required: true,
    },
    modalWidth: {
      type: String,
      default: () => '520',
    },
  },
  emits: ['update:modalVisible'],
  setup(props, { emit }) {
    const isVisible = computed(() => props.modalVisible)
    const title = computed(() => props.modalTitle)
    const width = computed(() => props.modalWidth)
    const handleCancel = () => {
      emit('update:modalVisible', false)
    }

    return {
      title,
      isVisible,
      handleCancel,
      width,
    }
  },
})
</script>

<style scoped></style>
